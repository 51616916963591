import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // baseApiUrl: 'http://www.drug.com/index.php/api/'
    baseApiUrl: 'http://yxs.huapeiwang.cn/index.php/api/'
    // baseApiUrl: 'http://chayao.test/index.php/api/'
  },
  getters: {
    baseApiUrl: state => {
      return state.baseApiUrl
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
