import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/layout/layout.vue'),
    // 二级路由
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home/home.vue'),
        meta: {
          title: '用药查询'
        }
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('@/views/my/my.vue'),
        meta: {
          title: '我的'
        }
      }
    ]
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/list/list.vue'),
    meta: {
      title: '查询列表'
    }
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('@/views/verification/verification.vue'),
    meta: {
      title: '绑定手机号'
    }
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('@/views/task/task.vue'),
    meta: {
      title: '每日任务'
    }
  },
  {
    path: '/attention',
    name: 'attention',
    component: () => import('@/views/attention/attention.vue'),
    meta: {
      title: '收藏药品'
    }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/record/record.vue'),
    meta: {
      title: '浏览记录'
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail/detail.vue'),
    meta: {
      title: '药品详情'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
]


/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

let beforeHeight, afterHeight;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  beforeHeight = window.innerHeight || document.documentElement.clientHeight;
  next();
})

// 修复兼容性 ios 底部返回功能
router.afterEach((to, from) => {
  setTimeout(() => {
    afterHeight = window.innerHeight || document.documentElement.clientHeight;
    if (beforeHeight != afterHeight) {
      if (!sessionStorage.getItem('ifHasWxBottom')) {
        if (history.length > 2) { //已经存在底栏
          sessionStorage.setItem('ifHasWxBottom', '1');
        } else if (history.length == 2) { //刚出现低栏
          sessionStorage.setItem('ifHasWxBottom', '1');
          location.reload();
        }
      }
    }
  }, 0);
});


export default router
