import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// vant 按需引入
import  './plugins/vant';
// 它会根据的手机尺寸来调整html标签上的font-size。
import 'amfe-flexible'

import { Toast } from 'vant';


// 全局注册
Vue.use(Toast);

import './styles/index.css'

Vue.prototype.Toast = Toast
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
