import Vue from 'vue'
// 引入所需的组件
import {
  Button,
  Tabbar, 
  TabbarItem,
  Field,
  Cell,
  CellGroup,
  Icon,
  Form,
  Overlay,
  ActionSheet,
  Popup,
  Sticky,
  Image as VanImage,
  Empty,
  Loading,
} from 'vant'

// 按需引入UI组件
Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Overlay)
Vue.use(ActionSheet)
Vue.use(Popup)
Vue.use(Sticky)
Vue.use(VanImage)
Vue.use(Empty)
Vue.use(Loading)
